<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import mobileView from "../../../components/mobile-view.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Data Supplier",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Supplier",
        },
      ],
      dataKey: "customer",
      screenWidth: 0,
      apiUrl: process.env.VUE_APP_API_BASE_URL + "suppliers",
      columns: [
        {
          prop: "name",
          label: "Name",
          width: "150",
        },
        {
          prop: "email",
          label: "Email",
          width: "150",
          textdefault: true,
        },
        {
          prop: "phone_number",
          label: "No.Hp",
          width: "150",
        },
        {
          prop: "province.name",
          label: "Provinsi",
          width: "150",
        },
        {
          prop: "regency.name",
          label: "Kab / Kota",
          width: "200",
        },
        {
          prop: "district.name",
          label: "Kecamatan",
          width: "200",
        },
        {
          prop: "village.name",
          label: "Desa / Kelurahan",
          width: "200",
        },
      ],
    };
  },
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-form-add="true"
          :form-url="'/supplier/tambah'"
          :with-filter-name="true"
          :with-delete="true"
          :with-print="false"
          :with-edit="true"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          :withFixed="true"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    title-filter="Filter Berdasarkan Nama, Email, & No HP"
    :with-filter-text="true"
    :form-url="'/supplier/tambah'"
    :data-url="apiUrl"
    :with-row-category="false"
    :card-customer="true"
    :card-default="false"
  ></mobileView>
</template>
